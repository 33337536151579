@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

/* Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Base Styles */

* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html,
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background: white !important;
}

@media (max-width: 768px) {
  html,
  body {
    font-size: 14px;
  }
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

button {
  cursor: pointer;
}

button:focus,
button:active,
input:focus,
input:active {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* react-datepicker librarie css */
.react-datepicker-wrapper {
  width: 100% !important;
}

.green-date {
  background-color: green;
  color: white;
}
.sreach-input-container {
  width: 100%;
  height: 3.5rem;
}
.react-datepicker-custom-style {
  background-color: white;
  color: #1d1d1f;
  height: 31px;
  width: 100px;
  padding: 0.5em 1em;
  border-radius: 0.2em;
  line-height: 1.5;
  border: 0.3px solid #999999;
  font-size: 0.75rem;
  font-weight: bold;
}
.react-datepicker-custom-disabled-style {
  background-color: transparent;
  color: #1d1d1f;
  height: 31px;
  width: 100px;
  padding: 0.5em 1em;
  border-radius: 0.2em;
  line-height: 1.5;
  border: 0.3px solid #999999;
  font-size: 0.75rem;
  font-weight: bold;
}
.trade-in-logo-style-one {
  fill: #ffffff;
}
.trade-in-logo-style-two {
  fill: #e30b17;
}
.ReactVirtualized__Grid__innerScrollContainer {
  min-height: 700px !important;
}

.countdown .content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  grid-gap: 6px;
  gap: 6px;
  padding-bottom: 1rem;
}
.countdown .box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.countdown .value {
  font-size: 16px;
  background-color: #053668;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 25px;
  height: 25px;
}

.countdown .text {
  font-size: 12px;
}

